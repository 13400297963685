import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import emojis from './data.json'
import { Container, Row, Col } from 'react-bootstrap';

function App() {
  let categories = emojis.map((e) => { return e.category })
  categories = categories.filter((value, index) => categories.indexOf(value) === index)

  const setClipboard = (emoji) => {
    navigator.clipboard.writeText(emoji);
  }

  return (
    <div className="App">
      <Container>
        <h1>CopyMoji.online</h1>
      { categories.map((categoryName) => { 
        return <Row className='mb-3'>
            <Col md={12}>
              <h2 dangerouslySetInnerHTML={{__html: categoryName}}></h2>
            </Col>
            { emojis.map((emoji) => { return emoji.category === categoryName ? <Col md={1} sm={2} xs={3} className={'emoji'} onClick={() => setClipboard(emoji.emoji)}>{ emoji.emoji }</Col> : <></> }) }
          </Row>
      }) }
      </Container>
    </div>
  );
}

export default App;
